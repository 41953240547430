import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Box } from "../../../components/elements";
import { Link, useLocation, useParams } from "react-router-dom";
import { Column } from "../../../Constant";
import { transform } from "../../../utills";

function ReusableNewenqiry({ profileleaddata }) {
  const [filteredData, setFilteredData] = useState();
  const [filters, setFilters] = useState({
    name: "",
    phone: "",
  });
  const { id } = useParams();
  const location = useLocation();
  useEffect(() => {
    setFilteredData(profileleaddata);
  }, [profileleaddata]);

  console.log("Profile Lead fiilter Data: ", filteredData);

  const vendorPath = location.pathname.startsWith("/vendor");
  const managerPath = location.pathname.startsWith("/manager");
  const counsellorPath = location.pathname.startsWith("/counsellor");
  const managerLeadStatus1 = location.pathname === "/manager/lead-status/1";
  const showLeadHistoryButton =
    ["2", "3", "4", "5"].includes(id) && !managerLeadStatus1;
  const adminPath =
    !vendorPath && !managerPath && !counsellorPath && !managerLeadStatus1;

    const handleChange = (e) => {
      const { name, value } = e.target;
  
      // Update filters state
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: value,
      }));
  
      // Filter the data based on name and phone in real-time
      const filteredResults = profileleaddata.filter((item) => {
        const matchesName = item.profile_name
          .toLowerCase()
          .includes(value.toLowerCase());  
        const matchesPhone = item.profile_mobile
          .toLowerCase()
          .includes(value.toLowerCase());  
        return matchesName || matchesPhone;
      });
      setFilteredData(filteredResults);
    };
  
   
  return (
    <>
      <Row>
        <Col xl="12" className="text-center py-5">
          <h1>
            {id === "1"
              ? "New Enquiry"
              : id === "2"
              ? "Hot 24"
              : id === "3"
              ? "Cold 24"
              : id === "4"
              ? "Super Cold 24"
              : id === "5" && "Thrash 24"}
          </h1>
        </Col>
      </Row>
      {counsellorPath && (
        <Row>
          <Form>
            <div className="d-flex justify-content-start mb-4 gap-3 align-items-baseline">
              <Form.Group className="d-flex justify-content-center mb-2">
                <Form.Label
                  column
                  sm="2"
                  className="d-flex align-items-center justify-content-center"
                >
                  Name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex justify-content-center mb-2">
                <Form.Label
                  column
                  sm="2"
                  className="d-flex align-items-center justify-content-center"
                >
                  Phone
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="number"
                    placeholder="Enter Phone Number"
                    name="phone"
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
             
            </div>
          </Form>
        </Row>
      )}

      <Row>
        {filteredData &&
          filteredData.map((item, index) => (
            <Col xl={12} className="mb-3" key={index + 1}>
              <Box className="mc-card">
                <div className="container-fluid">
                  <div className="row d-flex align-items-center">
                    <div
                      className={
                        managerLeadStatus1 ||
                        vendorPath ||
                        (!managerPath && !counsellorPath && !adminPath)
                          ? "col-12"
                          : "col-11"
                      }
                    >
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            {Column?.map((item, index) => (
                              <th key={item + index} scope="col">
                                {item}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{item.profile_name}</td>
                            <td>{item.councellor_name}</td>
                            <td>{item.vendor_code}</td>
                            <td>{item.last_lead_status}</td>
                            <td>{item.last_call_information}</td>
                            <td>{item.last_call_type}</td>
                            <td>{item.last_call_note}</td>
                            <td>{item.next_followup_date}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {counsellorPath && (
                      <div className="col-1">
                        <Link
                          type="button"
                          className="common-btn"
                          id="common-btn"
                          variant="primary"
                          to={`/counsellor/new-enquiryedit/${item.id}`}
                        >
                          Take Action
                        </Link>
                      </div>
                    )}
                    {adminPath && !counsellorPath && (
                      <div className="col-1">
                        <Link
                          type="button"
                          className="common-btn"
                          id="common-btn"
                          variant="primary"
                          to={`/new-enquiryedit/${item.id}`}
                        >
                          Take Action
                        </Link>
                      </div>
                    )}

                    {managerPath && showLeadHistoryButton && (
                      <div className="col-1">
                        <Link
                          type="button"
                          className="common-btn"
                          id="common-btn"
                          variant="primary"
                          to={`/manager/journeyhistory/${item.id}`}
                        >
                          Journey History
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </Box>
            </Col>
          ))}
      </Row>
    </>
  );
}

export default ReusableNewenqiry;
